import { css } from "@emotion/react";

type FlexboxProps = {
  direction?: "row" | "column";
  justify?: "space-between" | "center" | "start" | "end";
  align?: "center";
  gap?: string;
  height?: string;
  width?: string;
  padding?: string;
};

export const flexboxCss = ({
  direction,
  justify,
  align,
  gap,
  height = "100%",
  width = "100%",
  padding = "0px",
}: FlexboxProps) => [
  css`
    display: flex;
    height: ${height};
    width: ${width};
    box-sizing: border-box;
    padding: ${padding};
  `,
  direction &&
    css`
      flex-direction: ${direction};
    `,
  justify &&
    css`
      justify-content: ${justify};
    `,
  align &&
    css`
      align-items: ${align};
    `,
  gap &&
    css`
      gap: ${gap};
    `,
];
