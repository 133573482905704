import { css } from "@emotion/react";
import colors from "./colors";
import { transition } from "./transition";
import { map } from "lodash";

const BASE_COLOR = "slate";

const createTextColorRules = () =>
  map(
    colors[BASE_COLOR],
    (color, shade) => css`
      [data-color="${shade}"] {
        color: ${color};
      }
    `
  );

export const globalCss = css`
  body {
    background-color: ${colors[BASE_COLOR][900]};
    background-image: linear-gradient(
      23deg,
      ${colors.slate[900]} 0%,
      ${colors.slate[800]} 100%
    );

    color: ${colors[BASE_COLOR][50]};

    /* Set the color of the scrollbar track */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Set the color of the scrollbar thumb */
    &::-webkit-scrollbar-thumb {
      background-color: ${colors[BASE_COLOR][700]};
    }

    /* Set the color of the scrollbar thumb on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${colors[BASE_COLOR][600]};
    }

    scrollbar-color: ${colors[BASE_COLOR][500]} transparent;
  }

  ::selection {
    background-color: ${colors[BASE_COLOR][100]};
    color: ${colors[BASE_COLOR][800]};
  }

  p {
    margin: 5px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  strong {
    color: ${colors[BASE_COLOR][50]};
  }

  code {
    color: ${colors[BASE_COLOR][50]};
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > legend {
      font-weight: bold;
      color: ${colors[BASE_COLOR][100]};
      padding: 0;
      margin: 0;
      cursor: pointer;
    }

    & [data-form-actions] {
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 5px;
    }
  }

  &[data-is-panel] {
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
  }

  input,
  textarea {
    font-family: inherit;
    font-size: 90%;
    background-color: inherit;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${colors[BASE_COLOR][100]};

    transition: ${transition("box-shadow", "background-color")};

    &:focus {
      outline: none;
    }

    &[readonly] {
      background-color: ${colors[BASE_COLOR][700]};
      color: ${colors[BASE_COLOR][200]};
    }
  }

  textarea {
    min-height: 300px;
  }

  input[type="checkbox"] {
    padding: 0;
    margin: 0;
  }

  input[type="file"] {
    cursor: pointer;
  }

  a {
    color: ${colors[BASE_COLOR][100]};
    font-weight: 600;
    text-decoration: none;
    cursor: pointer;

    transition: ${transition("color")};

    &:hover {
      color: ${colors.sky[100]};
    }

    > strong {
      color: inherit;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    min-height: 36px;
    box-sizing: border-box;

    padding: 5px 10px;
    border: 1px solid ${colors[BASE_COLOR][100]};
    border-radius: 5px;
    color: ${colors[BASE_COLOR][300]};
    background-color: transparent;
    font-weight: 900;
    font-size: 70%;
    text-transform: uppercase;
    cursor: pointer;

    transition: ${transition("box-shadow", "color", "background-color")};

    &:hover {
      color: ${colors[BASE_COLOR][200]};
    }

    &:focus {
      outline: none;
      color: ${colors[BASE_COLOR][200]};
    }

    svg {
      fill: currentColor;
    }
  }

  button > svg,
  a > svg {
    min-width: 14px;
    min-height: 14px;
  }

  [data-button-group] {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  ${createTextColorRules()}
`;
